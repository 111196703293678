<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        Balance the following redox reaction in a basic solution. Note that both
        <chemical-latex content="H2O" />
        and
        <chemical-latex content="OH-" />
        are included on both sides of the reaction. Zeroes (0) should be placed in those input
        fields if they don't appear on that side of the reaction.
      </p>

      <p class="mb-2 pb-0">
        <v-text-field
          v-model="inputs.cu2Plus"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="Cu^2+(aq) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.no2Minus"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="NO2^-(aq) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.leftWater"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="H2O(l) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.leftOH"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="OH-(aq)" class="mb-n6" />
      </p>

      <p class="mt-0 pt-0 pl-14 mb-0">
        <stemble-latex content="$\ce{->} \,\,\,$" class="mb-n6" />
        <v-text-field
          v-model="inputs.cuPlus"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="Cu+(aq) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.no3Minus"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="NO3^-(aq) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.rightWater"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="H2O(l) + " class="mb-n6" />

        <v-text-field
          v-model="inputs.rightOH"
          class="d-inline-block mb-n6 centered-input mr-0"
          style="width: 20px"
        />
        <chemical-latex content="OH-(aq)" class="mb-n6" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question73',
  components: {
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        cu2Plus: 0,
        no2Minus: 0,
        leftWater: 0,
        leftOH: 0,
        cuPlus: 0,
        no3Minus: 0,
        rightWater: 0,
        rightOH: 0,
      },
    };
  },
  computed: {
    pctYield() {
      return this.taskState.getValueBySymbol('pctYield').content;
    },
    molCH4() {
      return this.taskState.getValueBySymbol('molCH4').content;
    },
  },
};
</script>
